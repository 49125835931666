import React from 'react';
import { Apple, ArrowRight, Facebook, Instagram, Smartphone } from 'lucide-react';

export default function ReturnPolicy() {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <a className="flex items-center justify-center" href="/">
          <Smartphone className="h-6 w-6" />
          <span className="ml-2 text-lg font-bold">Esta Mart</span>
        </a>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a className="text-sm font-medium hover:underline underline-offset-4" href="/#download">
            Download
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="/privacy-policy">
            Privacy
          </a>

          <a className="text-sm font-medium hover:underline underline-offset-4" href="/return-policy">
            Refund/Return Policy
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="/#contact">
            Contact
          </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48  justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Return Policy
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                  At Esta Mart, customer satisfaction is our top priority. If you're not satisfied with your order, we
                  offer a return policy that is simple and transparent.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800  justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Full Refund Within 2 Hours</h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  You can request a full refund within 2 hours of the delivered time. Refunds will be issued as wallet credits, which you can use on your future orders with us.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32  justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center ">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">How to Request a Refund</h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  To request a refund, simply go to your order history in the account section and select the order you'd like to return. You can also reach out to us directly on WhatsApp at +92 325 8161189.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">© 2024 Esta Mart. All rights reserved.</p>
      </footer>
    </div>
  );
}
